/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Increased opacity for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensures it appears above other content */
  animation: fadeIn 0.3s ease; /* Adds a smooth fade-in effect */
}

.modal {
  background: white;
  padding: 25px; /* Increased padding for a cleaner look */
  border-radius: 10px; /* Slightly larger radius for a smoother appearance */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Softer, more natural shadow */
  width: 90%;
  max-width: 500px; /* Increased max-width for larger screens */
  position: relative; /* Allows the close button to be positioned inside */
  animation: slideDown 0.3s ease; /* Slide-down effect for modal appearance */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 26px;
  color: #333; /* Changed to darker color for contrast on light background */
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}
.close-button:hover {
  color: #fff;
  background: #333; /* Dark background on hover */
  border-radius: 50%; /* Rounded background for a clean button look */
  padding: 8px 12px; /* Adjusted padding for the hover state */
}

.modal-content {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6; /* Improved readability */
  color: #555; /* Softer text color */
}

/* Keyframes for smooth animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
