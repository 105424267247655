body {
  background-color: #fffefe;
}
.logo {
  text-align: center;
  width: 100%;
  background-color: #45a049;
}

.sidebar {
  height: 100%;
  width: 19%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #45a049;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar p {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}
.btnside {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 6px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: black;
  display: block;
}
.btnside:hover {
  color: black;
  background-color: #71d876;
}
.sidebar button {
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 6px;
  color: black;
  cursor: pointer;
  background-color: #45a049;
}
.active {
  background-color: #fff;
  color: #000;
  font-weight: bold;
}
.main {
  top: 0;
  margin-left: 19%;
  padding: 0px 10px;
}
h2 {
  text-align: center;
  border: 1px solid black;
  margin: 0 3px;
  border-radius: 10px;
  padding: 6px;
}
h3 {
  text-align: center;
  border-bottom: 3px solid black;
}
.fixture {
  border: 1px solid black;
  border: none;
  margin-top: 100px;
}
.news {
  width: 60%;
  margin: 20px auto;
  border: 1px solid black;
  border: none;
}
.url {
  color: #5e5e5e;
  padding: 35px 10px;
  margin-top: 100px;
}
.dis {
  display: flex;
  flex-direction: column;
}
.livedata {
  display: flex;
  flex-direction: column;
}

.live {
  width: 90%;
  border: none;
  margin: 6px auto;
  padding: 10px 10px;
  background-color: rgb(233, 229, 229);
}
.live-btn {
  display: flex;
  justify-content: space-around;
}
.l-Btn {
  font-size: 19px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  margin: 8px 0;
  padding: 10px 18px;
  cursor: pointer;
  background-color: #71d876;
}
.tablefot {
  padding: 0 6px;
}
.item1 {
  color: #000;
  text-align: left;
  width: 100%;
  border: none;
  grid-area: round;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 4px;
  margin-top: 10px;
  background-color: #93c572;
}
.livediv {
  display: flex;
}
.item2 {
  font-weight: bold;
  font-size: 16px;
  padding: 10px 0;
  color: #000;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  align-items: center;
  border: none;
  width: 100%;
}
.item2 img {
  width: 5%;
}
.item2 span {
  width: 30%;
}
.leftName {
  text-align: right;
}
.rightName {
  text-align: left;
}
table {
  width: 100%;
  border-collapse: collapse;
}
tbody tr {
  height: 10px;
}
td {
  width: 25%;
  text-align: left;
}
.formInput {
  display: flex;
  flex-direction: column;
}
.formInput label {
  font-weight: bold;
  padding: 4px 0;
}
.formInput input {
  font-size: 20px;
  height: 30px;
}
.formInput textarea {
  height: 300px;
}
.formInput p {
  color: red;
}
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  background-color: #fff;
}

.form-control option {
  color: #333;
  background-color: #fff;
}

.uploadColumn {
  display: grid;
  grid-template-columns: auto auto auto;
}
.uploadColumn p {
  margin: auto;
  padding: 0 6px;
  font-size: 28px;
}
.uploadColumn input {
  height: 26px;
  padding: 0 6px;
  margin: 4px;
}
.uploadColumn label {
  display: none;
}
.uploadColumn span {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.uploadBottom {
  display: grid;
  grid-template-columns: auto auto;
  margin: 10px 0;
}
.uploadBottom input {
  height: 26px;
  padding: 0 6px;
  margin: 4px;
}
.uploadBottom label {
  margin: 0 4px;
  font-size: 20px;
  font-weight: bold;
}
.uploadForm {
  display: flex;
  flex-direction: column;
}
.formBtn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  margin: 8px auto;
  width: 30%;
  padding: 10px 18px;
  background-color: rgb(109, 106, 105);
}
.formBtn:hover {
  color: white;
  background-color: rgb(138, 137, 137);
}
h4 {
  text-align: center;
}
.login {
  width: 40%;
  border: none;
  margin: 100px auto;
  padding: 0 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  background-color: #eee;
}
.login form {
  margin: 30px auto;
}
.loginBtn {
  width: 100%;
  font-size: 19px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  margin: 8px 0;
  padding: 10px 18px;
  cursor: pointer;
  background-color: #71d876;
}
.loginBtn:hover {
  font-size: 16px;
  color: #f5f5f5;
  background-color: #2c682f;
}
.picForm {
  display: flex;
  flex-direction: column;
}

.custom-file-upload {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 8px 12px;
  width: 50%;
  margin: 10px auto;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
  font-family: Arial, sans-serif;
}

.custom-file-upload:hover {
  background-color: #e0e0e0;
}
.file-upload-input {
  display: none;
}
.upload-button {
  width: 30%;
  margin: auto;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #45a049;
}
.btn-delete {
  color: #eee;
  padding: 8px 16px;
  margin: 10px 0;
  background-color: rgb(245, 100, 100);
  border: none;
  cursor: pointer;
}
.btn-delete:hover {
  color: #000;
  background-color: red;
}
.user {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.user p {
  font-size: 18px;
}
.user-list {
  display: flex;
  justify-content: space-around;
}

.live-match {
  display: flex;
  flex-direction: column;
}
.live1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10px auto;
  padding: 10px 14px;
  border: 0.1px solid green;
  border-radius: 6px;
}
.live1 span {
  font-size: 20px;
  margin: 0 10px;
}
.live-status {
  font-size: 10px;
  margin: 0 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  animation: flash 2s infinite;
}
.div-formation h1{
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;

}
.formation{
  display: flex;
  justify-content: space-between;
}
.homePlayer{
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-top: 10px;
}
.clubsForm{
  display: flex;
  flex-direction: column;
}
.clubsForm button{
  cursor: pointer;
  width: 40%;
  min-width: 200px;
  margin: 20px auto;
  font-size: 20px;
  color: #fff;
  padding: 8px 18px;
  border: none;
  background-color: #45a049;
  border-radius: 4px;
}
.clubsForm button:hover{
  background-color: #44d64b;
}
@media screen and (max-width: 480px) {
  .main {
    display: none;
  }
  .sidebar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .main {
    display: none;
  }
  .sidebar {
    display: none;
  }
}
