/* news css */
.news {
  margin-top: 100px;
  padding: 0 10px;
}
.new {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
}
.new h3 {
  padding: 10px;
}
.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out;
}
.card p {
  margin: 4px 10px;
}
.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
}
.card:hover {
  background-color: #eee;
  transition: transform 0.3s ease-in-out;
}

.card h3 {
  margin-top: 10px;
  padding: 0 10px;
}
.card p {
  padding: 0 2px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}
.read {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  padding: 10px 16px;
  bottom: 0;
  background-color: #008080;
}
.read:hover {
  background-color: #016969;
  font-weight: bold;
  font-size: 16px;
  transition: transform 0.3s ease-in-out;
}
